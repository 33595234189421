/** @jsx jsx */
import {useRef, useState, useMemo, useCallback, useContext, createContext, useLayoutEffect, useEffect} from 'react'
import styled from "@emotion/styled"
import { jsx, Box } from 'theme-ui'

const ToggleContext = createContext()

function Toggle(props) {
  const [on, setOn] = useState(false)
  const [toggleHeight, setToggleHeight] = useState(0)
  const toggle = useCallback(() => setOn(oldOn => !oldOn), [])
  const [isOn] = useState(props.on)
  useEffect(() => {
      setOn(isOn)
    }, [isOn]
  )

  const value = useMemo(() => ({on, toggle, toggleHeight, setToggleHeight}), [on, toggle, toggleHeight])
  return (
    <ToggleContext.Provider value={value}>
      <Box sx={props.sx}>
        {props.children}
      </Box>
    </ToggleContext.Provider>
  )
}

function useToggleContext() {
  const context = useContext(ToggleContext)
  if (!context) {
    throw new Error(
      `Toggle compound components cannot be rendered outside the Toggle component`,
    )
  }
  return context
}

function Heading({children, index, ...props}) {
  const {toggle, on} = useToggleContext()
  const Wrapper = styled.button``

  return <Wrapper as={props.as} onClick={toggle} sx={{display: "flex", color: "text", m: 0, border: 'none', p: 0, font: "inherit", textAlign: "left", background: 'none', appearance: 'none', letterSpacing: "inherit", '&:active': {color: 'inherit'},...props.sx}} tabIndex={0} aria-expanded={on} >
    <Box sx={{mr: '.25em'}}>{index}</Box>
    <Box>{children} <span aria-hidden="true">{on ? '↗︎' : '↙︎'}</span></Box>
  </Wrapper>
}

function Content({children, ...props}) {
  const {on, toggleHeight, setToggleHeight} = useToggleContext()
  const ref = useRef()

  const resizeListener = useCallback(() => {
    setToggleHeight(`${ref.current.scrollHeight}px`)
    // on && setTimeout(() => setToggleHeight('none'), 300)
  }, [setToggleHeight])

  useEffect(() => {
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [resizeListener])

  useLayoutEffect(() => {
    resizeListener()
  }, [on, resizeListener])
  
  return (
    <div ref={ref} sx={{
      transition: "max-height .3s ease",
      overflow: "hidden",
      maxHeight: on ? toggleHeight : 0,
      ...props.sx}}
      aria-hidden={!on}
    >
      <Box sx={{
        transition: "opacity .3s, transform .3s",
        transform: `translateY(${on ? 0 : -60}px)`,
        opacity: on ? 1 : 0
      }}>
        {children}
      </Box>
    </div>
  )
}

Toggle.Heading = Heading
Toggle.Content = Content

export default Toggle