import React from 'react'
import { Box, Text, Flex, Grid, Heading, Styled } from 'theme-ui'
import Layout from "../components/layout"
import Image from "../components/Image"
import Link, {AppStore} from "../components/Link"
import Toggle from "../components/Toggle"
import SEO from "../components/seo"
import {Parallax} from "react-scroll-parallax"

export default () => 
  <Layout>
    <SEO keywords={['design', 'product designer', 'interface designer']} title='Simplify for Mac Case Study' description="A menu-bar macOS app that allows users to control desktop (Spotify, Rdio, iTunes, Vox) and web-based (SoundCloud, Pandora, Deezer) players via an elegant and easy-to-use interface." project="simplify" />

    <Heading as="h1" variant="text.h1" sx={{mb: 6}}>Simplify for Mac</Heading>
    <Box sx={{maxWidth: '50rem', mb: 8}}>
      <Text variant="h4">A menu-bar macOS app that allows users to control desktop (Spotify, <Box as="span" sx={{opacity: .5, filter: "blur(2px)"}}>Rdio,</Box> iTunes, Vox) and web-based (SoundCloud, Pandora, Deezer) players via an elegant and easy-to-use interface.</Text>

      <Flex sx={{
        mt: [4, 5], mx: [null, null, -4],
        justifyContent: 'space-between',
        flexDirection: ['column', 'row'],
        }}>
        <Link href="https://apps.apple.com/us/app/simplify-for-spotify-rdio/id448003584" title="Not available" sx={{variant: "links.button", cursor: "not-allowed", flexBasis: [null, '33%', 'auto', '33%'], 'div': {display: ['block', null, 'none', 'block']}}}>
          <AppStore sx={{my: -1}} />
          <span>Mac App Store</span>
        </Link>
      </Flex>
    </Box>

    <Box variant='boxes.gaplessImage' sx={{mb: 7}}>
      <Parallax y={[0, -15]} styleOuter={{maxHeight: "52.612vw"}}>
        <Image src="simplify/mini1.png" alt='App' />
      </Parallax>
    </Box>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="①" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        Idea behind the app
      </Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 4, pl: 'calc((1.025vw + 18.72px) * 1.1)'}}>
          <Text variant="h4" as="p" sx={{maxWidth: "800px", mb: 6, color: "muted"}}>
            When Spotify launched in the US back in 2011, I was happy to ditch my own iTunes music library and make a bet on the Swedish streaming service. However, I disliked that no tiny companion app was available to control it with keyboard shortcuts (with iTunes I used Bowtie).
          </Text>
        </Box>
        <Box sx={{mt: 5, mb: 8, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", gap: '1.5em', columnGap: 8}}>
            <Box>
              <Text as="p">With no prior experience in designing desktop apps, but with a willingness to create a music controller for Spotify, I designed the first version of Simplify.</Text>
              <Text as="p">
                To turn the idea into reality, I collaborated with a friend of mine (Daniel Vershinin) who is a very talented developer… and had no Objective-C experience at the time!
              </Text>
            </Box>
            <Text as="p">
              Simplify is a unique menu-bar app that allows you to take control of Spotify, Rdio, or iTunes, combining it with a range of customisable keyboard shortcuts (allowing quick access to functionality such as playback and volume control). The release gained a lot of press coverage much to our surprise (thanks to Federico Viticci).
            </Text>
          </Grid>
        </Box>
      </Toggle.Content>
    </Toggle>

    <Toggle sx={{my: 4}}>
      <Toggle.Heading index="②" sx={{variant: 'text.h2', cursor: 'pointer', '&:hover': {opacity: 0.3}}}>
        Future releases
      </Toggle.Heading>
      <Toggle.Content>
        <Box sx={{mt: 5, mb: 7, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
          <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", gap: '1.5em', columnGap: 8}}>
            <Text as="p" sx={{mb: 0}}>
              When the MVP was deemed sustainable, we continued to develop the app. Throughout the years, we've shipped two major releases of Simplify that provided additional functionality and improvements to the UI. We added the ability to control desktop music players on any Mac in your local network, and created a companion iOS app for remote access.
            </Text>
            <Text as="p">
            With the third major release, we introduced experimental support of web-based players, and open-sourced <Link blank href="https://github.com/din/simplify_web_suite_core">an API</Link> to allow developers to build plugins for Simplify. With the community, we added many popular music and podcasting services such as Deezer, Google Music, Soundcloud, Yandex.Music, Pocket Casts, and more.
            </Text>
          </Grid>
        </Box>

        <Box variant='boxes.image' sx={{mb: 8}}>
          <Image src="simplify/player.png" alt='App' />
        </Box>
        <Box variant='boxes.image' sx={{mb: 8}}>
          <Image src="simplify/ios.png" alt='Simplify for iOS' />
        </Box>

        <Grid sx={{gridTemplateColumns: "auto auto", columnGap: [4, null, 8], bg: "subtleBg", borderRadius: 3, py: 7, mt: 4, px: "calc((1.025vw + 18.72px) * 1.1)", alignItems: "center", mb: 8}}>
          <Text variant='caption' sx={{gridColumn: ["1/-1", "span 1"]}}>
            I combined another project of mine — <Link blank href="https://web.archive.org/web/20140713012905/http://tape.im/">Tape.im</Link> — into Simplify. This service allows users to share their currently playing tracks with a link that provided ways to listen to the song on Spotify, Rdio, Deezer, and iTunes Store.
          </Text>
          <Image
            src="simplify/sharing.png"
            alt='Sharing screen powered by the tape.im web-service'
            height="30vw"
            sx={{
              gridColumn: ["1/-1", "span 1"],
              mx: "auto",
            }} />
        </Grid>
      </Toggle.Content>
    </Toggle>

    <Box sx={{my: 4}}>
      <Text sx={{variant: 'text.h2', mb: 0}}>③ Results</Text>
      <Box sx={{mt: 5, mb: 6, pl: 'calc((1.025vw + 18.72px) * 1.1)', maxWidth: [null, "44rem", null, "76rem"]}}>
        <Grid sx={{gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))", gap: '1.5em', columnGap: 8}}>
          <Box as="ul" sx={{variant: 'styles.ul', mt: 0}}>
            <Styled.li><b>App #1 in Music category</b> in more than 50 countries including the US.</Styled.li>
            <Styled.li><b>+75K units sold</b> with a total revenue of $<Box as="span" sx={{display: "inline-block", bg: "text", width: "4.5em", height: "1.2em", mb: -1}}></Box>.</Styled.li>
            <Styled.li><b>#2 Product of the Day</b> on ProductHunt. And we didn't even know we were nominated!</Styled.li>
          </Box>
          <Box>
            <Text as="p">
              Simplify was mentioned on Lifehacker.com, Beautiful Pixels, Macstories, and awarded “Music App of the Year” by TUAW editors. It was also called out by iMore as the best music controller for Mac. 
            </Text>
          </Box>
        </Grid>
      </Box>
    </Box>
  </Layout>